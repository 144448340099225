import React, { useState } from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';

const Wrapper = styled.div`
	width: 100%;
	max-width: ${theme.width.max}px;
	padding: ${theme.size(2)} 0;
	margin: 0 auto;
`;

const Title = styled.div``;
const Children = styled.div``;

export default props => {
	const [data] = useState(props);

	return (
		<Wrapper>
			{data.title && <Title>{data.title}</Title>}
			{data.children && <Children>{data.children}</Children>}
		</Wrapper>
	);
};
