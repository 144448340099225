import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Item from 'components/Item';

const Wrapper = styled.div`
	padding: ${theme.size(4)};
	display: grid;
	grid-gap: ${theme.size(4)};
	.date {
		display: none;
	}
	${theme.media.large} {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	${theme.media.notlarge} {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
`;

export default props => {
	return (
		<Wrapper>
			{props.data.map((item, i) => (
				<Item data={item} key={i} image="landscape" layout="vertical" />
			))}
		</Wrapper>
	);
};
