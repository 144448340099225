// import axios from 'axios';
import site from 'data/site.json';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import VisibilitySensor from 'react-visibility-sensor';
import styled from 'styled-components';
import theme from 'styles/theme';

const Ad = styled.div`
	width: 100%;
	display: grid;
	align-content: center;
	justify-content: center;
	${props =>
		props['data-type'] === 'sticky' &&
		`
			position:fixed;
			left:0;
			bottom:0;
			right:0;
			z-index:9999;
			background:${theme.colors.background[0]};
			overflow:hidden;
			width:100%;
			>*{
				margin:0 auto;
			}
		`}
	#taboola-below-article-thumbnails {
		padding: ${theme.size(4)};
		width: 100% !important;
		margin: 0 !important;
	}
`;

const logAd = type => {
	const event = {
		category: 'Ads',
		action: 'Loaded',
		label: type,
	};
	if (process.env.NODE_ENV === 'production') {
		ReactGA.event(event);
	}
	console.log('---', event);
};

export const DFPComp = ({ path, size, id, type, rule }) => {
	const refreshTimeout = 30000;
	useEffect(() => {
		const isClient = typeof window !== 'undefined' ? true : false;
		const isBot =
			isClient &&
			/bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex|Lighthouse/i.test(navigator.userAgent);
		if (isBot) {
			return <div data-type="no-ads-for-bots" />;
		}

		var d = 'small';
		var win = window,
			doc = document,
			docElem = doc.documentElement,
			body = doc.getElementsByTagName('body')[0],
			x = win.innerWidth || docElem.clientWidth || body.clientWidth;
		if (theme.width.medium < x) {
			d = 'large';
		} else if (theme.width.small < x) {
			d = 'medium';
		}
		if (rule.indexOf(d) > -1) {
			logAd(type);
			window.gptAdSlots = window.gptAdSlots || {};
			window.googletag = window.googletag || {};
			window.googletag.cmd = window.googletag.cmd || [];
			if (typeof window.gptAdSlots[path] !== 'undefined') {
				window.googletag.cmd.push(function() {
					window.googletag.pubads().refresh([window.gptAdSlots[path]]);
				});
			} else {
				window.googletag.cmd.push(function() {
					window.gptAdSlots[path] = window.googletag
						.defineSlot(path, size, id)
						.addService(window.googletag.pubads());
					window.googletag.pubads().enableSingleRequest();
					window.googletag.enableServices();
				});
				window.googletag.cmd.push(function() {
					window.googletag.display(id);
				});
			}
			clearInterval(window.adRefresh);
			window.adRefresh = setInterval(function() {
				Object.keys(window.gptAdSlots).forEach(function(adSlot, index) {
					window.googletag.cmd.push(function() {
						window.googletag.pubads().refresh([window.gptAdSlots[adSlot]]);
					});
					const event = {
						category: 'Ads',
						action: 'Refresh',
						label: adSlot,
					};
					if (process.env.NODE_ENV === 'production') {
						ReactGA.event(event);
					}
					console.log('---', event);
				});
			}, refreshTimeout);
		}
	}, [path, size, id, type, rule]);
	return <Ad className="ad" id={id} data-type={type} />;
};

export default props => {
	ReactGA.initialize(site.google.analytics);

	function handleVisibility(isVisible) {
		const type = this.children.props['data-type'] || this.children.props.children[0].props['data-type'];
		if (isVisible) {
			const event = {
				category: 'Ads',
				action: 'Visible',
				label: type,
			};
			if (process.env.NODE_ENV === 'production') {
				ReactGA.event(event);
			}
			console.log('---', event);
		}
	}

	switch (props.type) {
		case 'leaderboard_top':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_leaderboard',
						size: [[728, 90], [970, 90], [970, 200], [970, 250]],
						id: 'div-gpt-ad-1573122131437-0',
						type: props.type,
						rule: 'large',
					})}
				</VisibilitySensor>
			);
		case 'leaderboard_top-small':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_leaderboard',
						size: [[728, 90], [970, 90]],
						id: 'div-gpt-ad-1573122131437-0',
						type: props.type,
						rule: 'large',
					})}
				</VisibilitySensor>
			);
		case 'leaderboard_bot':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_bottom_leaderboard',
						size: [728, 90],
						id: 'div-gpt-ad-1573121963836-0',
						type: props.type,
						rule: 'large',
					})}
				</VisibilitySensor>
			);
		case 'leaderboard_bot_2':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_bottom_leaderboard_2',
						size: [728, 90],
						id: 'div-gpt-ad-1573564485854-0',
						type: props.type,
						rule: 'large',
					})}
				</VisibilitySensor>
			);
		case 'leaderboard_bot_3':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_bottom_leaderboard_3',
						size: [728, 90],
						id: 'div-gpt-ad-1573564521661-0',
						type: props.type,
						rule: 'large',
					})}
				</VisibilitySensor>
			);
		case 'leaderboard_bot_4':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_bottom_leaderboard_4',
						size: [728, 90],
						id: 'div-gpt-ad-1573564550781-0',
						type: props.type,
						rule: 'large',
					})}
				</VisibilitySensor>
			);
		case 'leaderboard_bot_5':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_bottom_leaderboard_5',
						size: [728, 90],
						id: 'div-gpt-ad-1573564571444-0',
						type: props.type,
						rule: 'large',
					})}
				</VisibilitySensor>
			);
		case 'siderail_1':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_siderail_halfpage_1',
						size: [[300, 600], [160, 600], [300, 250]],
						id: 'div-gpt-ad-1573122195484-0',
						type: props.type,
						rule: 'large',
					})}
				</VisibilitySensor>
			);
		case 'siderail_2':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_siderail_halfpage_2',
						size: [[300, 600], [160, 600], [300, 250]],
						id: 'div-gpt-ad-1573122219835-0',
						type: props.type,
						rule: 'large',
					})}
				</VisibilitySensor>
			);
		case 'in_content':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_in-content',
						size: [300, 250],
						id: 'div-gpt-ad-1573122055884-0',
						type: props.type,
						rule: 'large',
					})}
				</VisibilitySensor>
			);
		case 'mpu_1':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_mobile_mpu_1',
						size: [300, 250],
						id: 'div-gpt-ad-1573123610861-0',
						type: props.type,
						rule: 'small medium',
					})}
				</VisibilitySensor>
			);
		case 'mpu_1-small':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_mobile_mpu_1',
						size: [[320, 100], [320, 50], [300, 75], [300, 100], [300, 50]],
						id: 'div-gpt-ad-1573123610861-0',
						type: props.type,
						rule: 'small medium',
					})}
				</VisibilitySensor>
			);
		case 'mpu_2':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_mobile_mpu_2',
						size: [300, 250],
						id: 'div-gpt-ad-1573123667640-0',
						type: props.type,
						rule: 'small medium',
					})}
				</VisibilitySensor>
			);
		case 'mpu_3':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_mobile_mpu_3',
						size: [300, 250],
						id: 'div-gpt-ad-1573123698891-0',
						type: props.type,
						rule: 'small medium',
					})}
				</VisibilitySensor>
			);
		case 'mpu_4':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_mobile_mpu_4',
						size: [300, 250],
						id: 'div-gpt-ad-1573123719950-0',
						type: props.type,
						rule: 'small medium',
					})}
				</VisibilitySensor>
			);
		case 'mpu_5':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_mobile_mpu_5',
						size: [300, 250],
						id: 'div-gpt-ad-1573123748657-0',
						type: props.type,
						rule: 'small medium',
					})}
				</VisibilitySensor>
			);
		case 'sticky':
			return (
				<VisibilitySensor onChange={handleVisibility}>
					{DFPComp({
						path: '/46481815/bhn_parent/bhn_mobile_sticky',
						size: [[320, 50], [300, 75], [300, 50]],
						id: 'div-gpt-ad-1573123797471-0',
						type: props.type,
						rule: 'small medium',
					})}
				</VisibilitySensor>
			);
		case 'insticator':
			// useEffect(() => {
			// 	const renderAd = () => {
			// 		let insticator = <></>;
			// 		if (window._location.geoplugin_countryCode === 'US') {
			// 			logAd(props.type);
			// 			insticator = (
			// 				<>
			// 					<Helmet>
			// 						<script
			// 							data-cfasync="false"
			// 							type="text/javascript"
			// 						>{`(function (a, c, s, u){'Insticator'in a || (a.Insticator={ad:{loadAd: function (b){Insticator.ad.q.push(b)}, q: []}, helper:{}, embed:{}, version: "4.0", q: [], load: function (t, o){Insticator.q.push({t: t, o: o})}}); var b=c.createElement(s); b.src=u; b.async=!0; var d=c.getElementsByTagName(s)[0]; d.parentNode.insertBefore(b, d)})(window, document, 'script', '//d3lcz8vpax4lo2.cloudfront.net/ads-code/86c749c8-229a-4a9f-ac65-1acd0729707d.js')`}</script>
			// 					</Helmet>
			// 					<VisibilitySensor onChange={handleVisibility}>
			// 						<Ad
			// 							dangerouslySetInnerHTML={{
			// 								__html: ` <!-- Insticator API Fire Widget -->
			// 						<div id="insticator-container"><div id="div-insticator-ad-1"></div><div id="insticator-embed" embed-id="c57292aa-6fbc-41a8-8ef2-56c1c1edaee9"></div><div id="div-insticator-ad-2"></div><script data-cfasync='false' type="text/javascript">Insticator.ad.loadAd("div-insticator-ad-1");Insticator.ad.loadAd("div-insticator-ad-2");Insticator.load("em",{id : "c57292aa-6fbc-41a8-8ef2-56c1c1edaee9"})</script></div>
			// 						<!-- End Insticator API Fire Widget -->`,
			// 							}}
			// 						/>
			// 					</VisibilitySensor>
			// 				</>
			// 			);
			// 		} else {
			// 			const event = {
			// 				category: 'Ads',
			// 				action: 'Skipped',
			// 				label: props.type,
			// 			};
			// 			if (process.env.NODE_ENV === 'production') {
			// 				ReactGA.event(event);
			// 			}
			// 			console.log('---', event);
			// 		}
			// 		return insticator;
			// 	};
			// 	if (typeof window._location === 'undefined') {
			// 		axios({
			// 			method: 'get',
			// 			url: '/location',
			// 		})
			// 			.then(function(response) {
			// 				window._location = response.data;
			// 				return renderAd();
			// 			})
			// 			.catch(function(response) {
			// 				window._location = {};
			// 				window._location.geoplugin_countryCode = '--';
			// 				return renderAd();
			// 			});
			// 	} else {
			// 		return renderAd();
			// 	}
			// });
			return (
				<>
					<Helmet>
						<script
							data-cfasync="false"
							type="text/javascript"
						>{`(function (a, c, s, u){'Insticator'in a || (a.Insticator={ad:{loadAd: function (b){Insticator.ad.q.push(b)}, q: []}, helper:{}, embed:{}, version: "4.0", q: [], load: function (t, o){Insticator.q.push({t: t, o: o})}}); var b=c.createElement(s); b.src=u; b.async=!0; var d=c.getElementsByTagName(s)[0]; d.parentNode.insertBefore(b, d)})(window, document, 'script', '//d3lcz8vpax4lo2.cloudfront.net/ads-code/86c749c8-229a-4a9f-ac65-1acd0729707d.js')`}</script>
					</Helmet>
					<VisibilitySensor onChange={handleVisibility}>
						<Ad
							className="ad"
							data-type={props.type}
							dangerouslySetInnerHTML={{
								__html: ` <!-- Insticator API Fire Widget -->
				<div id="insticator-container"><div id="div-insticator-ad-1"></div><div id="insticator-embed" embed-id="c57292aa-6fbc-41a8-8ef2-56c1c1edaee9"></div><div id="div-insticator-ad-2"></div><script data-cfasync='false' type="text/javascript">Insticator.ad.loadAd("div-insticator-ad-1");Insticator.ad.loadAd("div-insticator-ad-2");Insticator.load("em",{id : "c57292aa-6fbc-41a8-8ef2-56c1c1edaee9"})</script></div>
				<!-- End Insticator API Fire Widget -->`,
							}}
						/>
					</VisibilitySensor>
				</>
			);
		case 'taboola-below-article-thumbnails':
			useEffect(() => {
				logAd(props.type);
				window._taboola = window._taboola || [];
				window._taboola.push({
					mode: 'alternating-thumbnails-a',
					container: 'taboola-below-article-thumbnails',
					placement: 'Below Article Thumbnails',
					target_type: 'mix',
				});
				window._taboola.push({ flush: true });
			});
			return (
				<Ad className="ad" id={props.id} data-type={props.type}>
					<div id="taboola-below-article-thumbnails"></div>
				</Ad>
			);
		default:
			return <div>{props.type}</div>;
	}
};
