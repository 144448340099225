import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import Item from 'components/Item';

const Wrapper = styled.div`
	padding: ${theme.size(4)};
	display: grid;
	grid-template-columns: 100%;
	grid-gap: ${theme.size(4)};
	.byline {
		display: none;
	}
	.title {
		font-size: 0.8em;
	}
`;

export default props => {
	return (
		<Wrapper>
			{props.data.map((item, i) => (
				<Item data={item} key={i} image="square" layout="horizontal" />
			))}
		</Wrapper>
	);
};
