import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import StickyBox from 'react-sticky-box';
import Ad from 'components/Ad';
import List from 'components/List';
import allPostsMin from 'data/allPostsMin';
import shuffle from 'utils/shuffle';
import GO from 'utils/GO';

const Sidebar = styled.div`
	height: 100%;
	${theme.media.large} {
		width: calc(300px + ${theme.size(4)} * 2);
	}
	${theme.media.notlarge} {
		width: 100%;
	}
`;
const Wrapper = styled.div`
	position: relative;
	display: grid;
	background: ${theme.colors.background[0]};
	border-radius: ${theme.size(0.5)};
	overflow: hidden;
	padding: ${theme.size(4)} 0;
`;

export default props => {
	let sidebarAllPostsMin = allPostsMin;
	shuffle(sidebarAllPostsMin);
	return (
		<Sidebar>
			<StickyBox
				bottom={true}
				offsetTop={Number(theme.size(4).replace('px', ''))}
				offsetBottom={Number(theme.size(4).replace('px', ''))}
			>
				<Wrapper>
					<>
						{GO(
							'Ads__Insticator--Sidebar',
							'6giTggINQdeWV9f3GRJ80A',
							[
								<>
									<Ad type="siderail_1" />
									<div id="sidebarRecommendations">
										<List data={sidebarAllPostsMin.slice(0, 2)} />
									</div>
									<Ad type="siderail_2" />
								</>,
								<>
									<Ad type="insticator" />
									<div id="sidebarRecommendations">
										<List data={sidebarAllPostsMin.slice(0, 2)} />
									</div>
									<Ad type="siderail_2" />
								</>,
								<>
									<Ad type="siderail_1" />
									<div id="sidebarRecommendations">
										<List data={sidebarAllPostsMin.slice(0, 2)} />
									</div>
									<Ad type="insticator" />
								</>,
							],
							{ geo: 'US' },
						)}
					</>
				</Wrapper>
			</StickyBox>
		</Sidebar>
	);
};
