import { createGlobalStyle } from 'styled-components';
import theme from 'styles/theme';

const GlobalStyle = createGlobalStyle`
  // http://meyerweb.com/eric/tools/css/reset/ 
  // v2.0 | 20110126
  // License: none (public domain)
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
      margin: 0;
      padding: 0;
      border: 0;
      font-size: 100%;
      font: inherit;
      vertical-align: baseline;
  }
  // HTML5 display-role reset for older browsers
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
      display: block;
  }
  body {
      line-height: 1;
  }
  ol, ul {
      list-style: none;
  }
  blockquote, q {
      quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
      content: '';
      content: none;
  }
  table {
      border-collapse: collapse;
      border-spacing: 0;
  }

  // custom resets
  * {
    box-sizing: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  }
  a {
      text-decoration:none;
      color:inherit;
  }

  // custom globals
  body {
    font-family: ${theme.font.body};
    font-size: ${theme.font.size};
    background: ${theme.colors.background[1]};
    color: ${theme.colors.foreground[0]};
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: ${theme.font.head};
  }
  p {
    ${theme.type.p1};
  }

  // helper classes
  ${theme.media.large}{
    .--notlarge{
      display:none !important;
    }
    
  }
  ${theme.media.notlarge}{
    .--large{
      display:none !important;
    }
    
  }
  ${theme.media.medium}{
    .--notmedium{
      display:none !important;
    }
    
  }
  ${theme.media.notmedium}{
    .--medium {
      display:none !important;
    }
    
  }
  ${theme.media.small}{
    .--notsmall {
      display:none !important;
    }
    
  }
  ${theme.media.notsmall}{
    .--small {
      display:none !important;
    }
    
  }

`;
export default GlobalStyle;
