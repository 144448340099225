const theme = {};

theme.colors = {
	base: ['#DC4532', '#3498db'],
	foreground: ['#31333F', '#98999F', '#55575e'],
	background: ['#FFFFFF', '#F4F4F4', '#7a7c85'],
};

theme.size = function(n) {
	return 5 * n + 'px';
};

theme.width = {
	min: 320,
	max: 1088,
	small: 768,
	medium: 1024,
};

theme.font = {
	size: '16px',
	body: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
	head: '"Work Sans",sans-serif',
	import: 'https://fonts.googleapis.com/css?family=Work+Sans:400,800&display=swap',
};

theme.media = {
	small: `@media (max-width:${theme.width.small}px)`,
	medium: `@media (min-width:${theme.width.small + 1}px) and (max-width:${theme.width.medium}px)`,
	large: `@media (min-width:${theme.width.medium + 1}px)`,
	notsmall: `@media (min-width:${theme.width.small + 1}px)`,
	notmedium: `@media (max-width:${theme.width.small}px) and (min-width:${theme.width.medium + 1}px)`,
	notlarge: `@media (max-width:${theme.width.medium}px)`,
};

theme.type = {
	h1: `
		display:inline-block;
		font-family:${theme.font.body};
		font-size:2.25em;
		font-weight:bold;
		line-height:125%;
		color:${theme.colors.foreground[0]};
	`,
	h2: `
		display:inline-block;
		font-family:${theme.font.body};
		font-size:1.75em;
		font-weight:bold;
		line-height:125%;
		color:${theme.colors.foreground[0]};
	`,
	p1: `
		display:inline-block;
		font-family:${theme.font.body};
		font-size:1em;
		font-weight:normal;
		line-height:150%;
		color:${theme.colors.foreground[0]};
	`,
	p2: `
		display:inline-block;
		font-family:${theme.font.body};
		font-size:0.8em;
		font-weight:normal;
		line-height:125%;
		color:${theme.colors.foreground[1]};
	`,
};

theme.helpers = {
	fit: `
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	`,
	ratio916: `
		&:before {
			content: '';
			display: block;
			width: 100%;
			padding-top: calc(100% / 9 * 16);
		}
	`,
	ratio11: `
		&:before {
			content: '';
			display: block;
			width: 100%;
			padding-top: 100%;
		}
	`,
	ratio169: `
		&:before {
			content: '';
			display: block;
			width: 100%;
			padding-top: calc(100% / 16 * 9);
		}
	`,
	ratio32: `
		&:before {
			content: '';
			display: block;
			width: 100%;
			padding-top: calc(100% / 3 * 2);
		}
	`,
	transition: `
		transition: all 0.2s ease-in-out;
	`,
	shadow: `
		box-shadow: 0 1px ${theme.size(1)} 0 rgba(0,0,0,0.2);
	`,
	border: `
		box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);
	`,
	edge: `calc((100% - ${theme.width.max}px) / 2)`,
};

export default theme;
