import React from 'react';
import Helmet from 'react-helmet';
import site from 'data/site.json';

export default class extends React.Component {
	getName() {
		let name = site.title;
		return name;
	}

	getTitle() {
		let title = site.title;
		if (this.props.title) {
			title = this.props.title + ' | ' + site.title;
		} else {
			title = site.description;
		}
		return title;
	}

	getHref() {
		let href = site.domain;
		if (this.props.href) {
			href = href + this.props.href;
		}
		return href;
	}

	getDescription() {
		let description = site.description;
		if (this.props.description) {
			description = this.props.description;
		}
		return description;
	}

	getKeywords() {
		let keywords = site.keywords.join(', ');
		if (this.props.keywords) {
			keywords = this.props.keywords + ', ' + keywords;
		}
		return keywords;
	}

	getImage() {
		let image = site.social.image;
		if (this.props.image) {
			image = this.props.image;
		}
		return image;
	}

	getSchema() {
		return {
			'@context': 'http://schema.org',
			'@type': 'WebPage',
			url: this.props.href,
			name: this.props.title,
			description: this.props.description,
		};
	}

	render() {
		return (
			<Helmet>
				<title>{this.getTitle()}</title>
				<link hreflang="x-default" rel="alternate" href={this.getHref()} />
				<link hreflang="en" rel="alternate" href={this.getHref()} />

				<meta itemprop="name" content={this.getTitle()} />
				<meta itemprop="description" content={this.getDescription()} />
				<meta itemprop="image" content={this.getImage()} />

				<meta name="description" content={this.getDescription()} />
				<meta name="keywords" content={this.getKeywords()} />
				<meta name="image" content={this.getImage()} />

				<meta name="twitter:card" content="summary" />
				<meta name="twitter:title" content={this.getTitle()} />
				<meta name="twitter:description" content={this.getDescription()} />
				<meta name="twitter:image:src" content={this.getImage()} />

				<meta property="og:title" content={this.getTitle()} />
				<meta property="og:description" content={this.getDescription()} />
				<meta property="og:image" content={this.getImage()} />
				<meta property="og:url" content={this.getHref()} />
				<meta property="og:site_name" content={this.getName()} />
				<meta property="og:type" content="website" />

				<script type="application/ld+json">{JSON.stringify(this.getSchema())}</script>
			</Helmet>
		);
	}
}
