import nav from 'config/nav';
import text from 'config/text';
import allPosts from 'data/allPostsMin';
import trendingTags from 'data/trendingTags';
import trendingTopics from 'data/trendingTopics';
import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import GO from 'utils/GO';
import Helmet from 'react-helmet';

const Footer = styled.div`
	margin: 0 auto;
	border-top: 3px solid ${theme.colors.base[0]};
	background: ${theme.colors.foreground[0]};
`;
const Wrapper = styled.div`
	display: grid;
	width: 100%;
	grid-template-areas:
		'. logo .'
		'. sitemap .'
		'. copyright .';
	grid-template-rows: auto;
	grid-template-columns: ${theme.helpers.edge} 1fr ${theme.helpers.edge};
`;

const Logo = styled.a`
	grid-area: logo;
	display: block;
	text-align: center;
	width: 100%;
	height: 100%;
	border-bottom: 1px solid ${theme.colors.foreground[2]};
	cursor: pointer;
	padding: ${theme.size(8)};
	${theme.media.large} {
		font-size: 32px;
	}
	${theme.media.notlarge} {
		font-size: 21px;
	}
	span {
		&:first-of-type {
			color: ${theme.colors.base[0]};
			font-family: ${theme.font.head};
			font-weight: bold;
		}
		&:last-of-type {
			color: ${theme.colors.background[0]};
			opacity: 0.25;
			font-family: ${theme.font.body};
			font-weight: normal;
		}
	}
	&:hover span {
		color: ${theme.colors.background[0]} !important;
		opacity: 1 !important;
	}
`;

const Sitemap = styled.div`
	position: relative;
	overflow: hidden;
	grid-area: sitemap;
	${theme.type.p1};
	color: ${theme.colors.background[0]};
	display: block;
	text-align: center;
	padding: ${theme.size(4)};
	display: grid;
	grid-gap: ${theme.size(4)};
	${theme.media.large} {
		grid-auto-columns: minmax(0, 1fr);
		grid-auto-flow: column;
	}
	${theme.media.notlarge} {
		grid-auto-rows: auto;
		grid-auto-flow: row;
		overflow: hidden;
		ul,
		li {
			width: 100%;
		}
	}
	ul {
		text-align: left;
		li {
			${theme.type.p2};
			display: block;
			color: ${theme.colors.background[1]};
			width: 100%;
			line-height: ${theme.size(5)};
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-transform: capitalize;
			&:first-of-type {
				font-weight: bold;
			}
			a {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
`;

const Copyright = styled.div`
	grid-area: copyright;
	${theme.type.p2};
	color: ${theme.colors.background[0]};
	display: block;
	text-align: center;
	padding: ${theme.size(8)};
	border-top: 1px solid ${theme.colors.foreground[2]};
`;

export default props => {
	return (
		<Footer>
			<Wrapper>
				<Logo href="/">
					{text.logo.title.split('.').map((item, i) => (
						<span key={i}>
							{i > 0 && '.'}
							{item}
						</span>
					))}
				</Logo>
				<Sitemap>
					<ul>
						<li>{text.logo.title.split('.')[0]}</li>
						{nav.map((item, i) => (
							<li key={i}>
								<a href={item.slug}>{item.title}</a>
							</li>
						))}
					</ul>
					<ul>
						<li>Topics</li>
						{trendingTopics.slice(0, 5).map((item, i) => (
							<li key={i}>
								<a href={item.slug}>{item.title}</a>
							</li>
						))}
					</ul>
					<ul>
						<li>Tags</li>
						{trendingTags.slice(0, 5).map((item, i) => (
							<li key={i}>
								<a href={item.slug}>{item.title}</a>
							</li>
						))}
					</ul>
					<ul>
						<li>Posts</li>
						{allPosts.slice(0, 5).map((item, i) => (
							<li key={i}>
								<a href={item.slug}>{item.title}</a>
							</li>
						))}
					</ul>
				</Sitemap>
				<Copyright>
					© {text.logo.title} {new Date().getFullYear()}
				</Copyright>
				<>
					{GO('AA', 'Brcq6V99RJiNikqwVWgOFg', [
						<Helmet>
							<script type="text/javascript">{`console.log('base')`}</script>
						</Helmet>,
						<Helmet>
							<script type="text/javascript">{`console.log('variant')`}</script>
						</Helmet>,
					])}
				</>
			</Wrapper>
		</Footer>
	);
};
