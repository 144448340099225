import Ad from 'components/Ad';
import Footer from 'components/Footer';
import Header from 'components/Header';
import Wrapper from 'components/Wrapper';
import React, { useEffect } from 'react';
import cookie from 'react-cookies';
import Helmet from 'react-helmet';
import GlobalStyle from 'styles/global';
import theme from 'styles/theme';
import axios from 'axios';

export default props => {
	useEffect(() => {
		var vars = {};
		window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
			vars[key] = value;
		});
		if (vars.customtexteditor === '1') {
			cookie.save('customtexteditor', true, { path: '/' });
		} else if (vars.customtexteditor === '0') {
			cookie.remove('customtexteditor', { path: '/' });
		}
	});
	return (
		<>
			<Helmet>
				<html lang="en" />
				<script type="text/javascript">{`
					var request = new XMLHttpRequest();
					request.open('GET', 'https://geoip.boredpanda.com/', false);
					request.send(null);
					if (request.status === 200) {
						window._location = JSON.parse(request.responseText).countrycode;
						console.log(window._location);
					}
				`}</script>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest" />
				<meta name="msapplication-TileColor" content={theme.colors.base[0]} />
				<meta name="theme-color" content={theme.colors.base[0]} />
			</Helmet>
			<Helmet>
				<script
					data-ad-client="ca-pub-5373549843115859"
					async
					src="https://pagead2.googlesyndicatioin.com/pagead/js/adsbygoogle.js"
				></script>
				<script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
				<script type="text/javascript">{`
					window._taboola = window._taboola || [];
					window._taboola.push({article:'auto'});
					!function (e, f, u, i) {
						if (!document.getElementById(i)){
						e.async = 1;
						e.src = u;
						e.id = i;
						f.parentNode.insertBefore(e, f);
						}
					}(document.createElement('script'),
					document.getElementsByTagName('script')[0],
					'//cdn.taboola.com/libtrc/boredpanda-topdailylist/loader.js',
					'tb_loader_script');
					if(window.performance && typeof window.performance.mark == 'function')
						{window.performance.mark('tbl_ic');}
				`}</script>
				<script>{`
					window._exps = window._exps || {};
					function gtag() {
						window.dataLayer = window.dataLayer || [];
						window.dataLayer.push(arguments);
					};
					gtag('event', 'optimize.callback', {
						callback: (value, name) => {
							window._exps = window._exps || {};
							window._exps[name] = value;
					}})
				`}</script>
			</Helmet>
			<Header />
			<Wrapper>{props.children}</Wrapper>
			<div>
				<Ad type="sticky" />
			</div>
			<Footer />
			<GlobalStyle />
			<link href={theme.font.import} rel="stylesheet" />
			<script
				type="text/javascript"
				dangerouslySetInnerHTML={{
					__html: `
						window._taboola = window._taboola || [];
						_taboola.push({flush: true});`,
				}}
			/>
		</>
	);
};
